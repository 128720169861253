@import "~@o4c/css/src/mixins/elevations";
@import "~@o4c/css/src/core/typography";

.screen-header {
  @include bodyText();
  @include elevation4();
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  background: var(--white);
  overflow: hidden;
  padding: 16px;
  z-index: 1;
  &.sticky-top {
    position: sticky;
    top: 0;
  }
  &.sticky-bottom {
    position: sticky;
    bottom: 0;
  }
  i.icon-arrow-down {
    padding: 0 10px 0 0;
    color: var(--grey-dark);
  }
  .text {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    flex-direction: column;
    color: var(--dark);
    .subtitle {
      @include smallText();
      margin: 0 0 4px 0;
      color: var(--primary);
      text-transform: uppercase;
      display: block;
    }
    i {
      color: var(--grey-dark);
    }
    .location {
      margin: 4px 0 0 0;
      font-weight: normal;
    }
  }
}
