@import "~@o4c/css/src/mixins/elevations";

.no-trips-container {
  background-color: var(--grey-lighter);
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 16px;
  display: flex;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h2 {
    color: var(--grey-dark);
    font-weight: bold;
  }
  .icon-wrapper {
    height: 96px;
    width: 96px;
    border-radius: 96px;
    background-color: var(--white);
    border: 1px solid var(--grey-light);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  i.icon {
    font-size: 48px;
    color: var(--primary);
  }
}
