@import "~@o4c/css/src/mixins/typography";
@import "~@o4c/css/src/mixins/elevations";

.slide-up-panel-container {
  .o4c.button.slide-up-panel-trigger,
  .o4c.button.slide-up-panel-trigger:hover {
    @include elevation6();
    position: fixed;
    z-index: 810;
    bottom: 24px;
    right: 16px;
    background-color: var(--white);
    i {
      color: var(--grey-dark);
    }
  }

  &.visible {
    .slide-up-panel {
      bottom: 0;
    }
  }
  
  .slide-up-panel {
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    width: 100%;
    z-index: 830;
    bottom: -100vh;
    background-color: var(--grey-lighter);
    transition: bottom 0.3s ease-in-out;
    transition-delay: 0.3s;
    .header {
      display: flex;
      padding: 16px;
      background-color: var(--white);
      border-bottom: 1px solid var(--grey-light);
      span {
        @include bodyText();
        flex: 1;
        font-weight: bold;
        padding-left: 8px;
      }
      i {
        color: var(--primary);
      }
    }
    .leaflet-container {
      @include elevation2();
      height: 240px;
      .marker {
        background-image: url("../../../assets/marker.svg");
        background-size: auto;
        background-repeat: no-repeat;
        width: 32px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin-left: auto;
        margin-right: auto;
        .number {
          @include bodyText();
          background-color: var(--primary);
          color: var(--on-primary);
          padding: 8px 8px 0 8px;
          border-radius: 100%;
          font-weight: bold;
        }
      }
    }
    .container {
      padding: 16px;
      .info {
        display: flex;
        margin-bottom: 16px;
        .number {
          @include titleHeader();
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--primary);
          color: var(--on-primary);
          font-weight: bold;
          border-radius: 8px;
          border: 1px solid var(--primary-border);
        }
        .codes {
          flex-grow: 1;
          padding-left: 16px;
          .label {
            @include extraSmallText();
            font-weight: bold;
            color: var(--primary);
            text-transform: uppercase;
          }
        }
        i.small {
          font-size: 16px;
        }
        a {
          text-decoration: none;
        }
      }
      .address {
        @include bodyText();
        color: var(--grey-dark);
      }
    }
  }
}

.hidden {
  display: none !important;
}
